


















import useUser from '@/use/user'
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  components: { OVehiclesList: () => import('@/components/organisms/o-vehicles-list.vue') },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      const that = vm as any
      that.lastVehicle = null
      if (from.name === 'panel.vehicle.view') that.lastVehicle = from.params.id || null
    })
  },

  setup(_, { root }) {
    const { hasAccessTo } = useUser({ root })

    const lastVehicle = ref<string | null>(null)

    return { hasAccessTo, lastVehicle }
  }
})
